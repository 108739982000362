import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import ExternalLink from "@svgs/external-link.svg";
import { StaticImage } from "gatsby-plugin-image"
import BreadCrumbs from "@components/breadcrumbs";

const Card = ( {text, href}) => (
  <div className="shadow h-16 mb-4">
    <a href={href} target="_blank" rel="noopener noreferrer" className="flex items-center justify-between w-full h-full px-5">
      <span>{text}</span>
      <ExternalLink className="w-6 flex-shrink-0"/>
    </a>
  </div>
);

const CardWithImage = (props) => (
  <div className="shadow mb-4 flex justify-between">
      {props.children}
    <div className="w-1/2 inline-flex">
      <a href={props.href} target="_blank" rel="noopener noreferrer" className="flex items-center justify-between w-full h-full px-5">
        <span className="text-primary">{props.text}</span>
        <ExternalLink className="text-primary w-6 flex-shrink-0" />
      </a>
    </div>
  </div>
);

const Business =  () => (
  <Layout>
    <Meta
      title="取扱い商品"
      desc="木原興業株式会社は、時代のニーズにあったシステムや製品を日々、提案し続けています。"
    />{}
    <Heading.H1 text="取扱い商品"/>
      <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "事業内容",
            path: "/business",
          },
          {
            string: "取扱い商品",
          }
        ]}
      />
      <Heading.H3 text="富士電機グループ製品" />
      <div className="lg:grid lg:grid-cols-2 lg:gap-4">
        <CardWithImage text="電磁開閉器" href="https://www.fujielectric.co.jp/products/electromagnetic_switch/">
          <StaticImage objectFit="contain" src="../images/magnet-switch.png" alt="magnet-switch" className="w-1/2 m-4" />
        </CardWithImage>
        <CardWithImage text="配線用遮断器・漏電遮断器" href="https://www.fujielectric.co.jp/products/low_voltage_circuit_breaker/">
          <StaticImage objectFit="contain" src="../images/barrier.png" alt="barrier" className="w-1/2 m-4" />
        </CardWithImage>
        <CardWithImage text="操作表示機器" href="https://www.fujielectric.co.jp/products/control_equipment/">
          <StaticImage objectFit="contain" src="../images/indicator.png" alt="indicator" className="w-1/2 m-4" />
        </CardWithImage>
        <CardWithImage text="低圧インバータ" href="https://www.fujielectric.co.jp/products/inverter/index.html">
          <StaticImage objectFit="contain" src="../images/inverter.png" alt="inverter" className="w-1/2 m-4" />
        </CardWithImage>
        <Card text="MMS・SSC・ソフトスタータ" href="https://www.fujielectric.co.jp/products/mms_ssc_soft/" />
        <Card text="CP・低圧限流ヒューズ・ACB" href="https://www.fujielectric.co.jp/products/circuit_protector_lv_clfuse/" />
        <Card text="制御リレー・タイマ・FAセンサ" href="https://www.fujielectric.co.jp/products/control_relay_timer/" />
        <Card text="盤内高圧機器" href="https://www.fujielectric.co.jp/products/hv_distribution_digital/" />
        <Card text="エネルギー管理機器" href="https://www.fujielectric.co.jp/products/power_monitoring/" />
        <Card text="モータ／ブレーキモータ／ギアードモータ／同期ドライブシステム" href="https://www.fujielectric.co.jp/products/motor/index.html" />
        <Card text="プログラマブルコントローラ（PLC）" href="https://www.fujielectric.co.jp/products/plc/index.html" />
        <Card text="プログラマブル表示器" href="https://hakko-elec.co.jp/index.html" />
      </div>
      <Heading.H3 text="その他各種製品" />
      <p>直接お問い合わせください</p>

    </div>
  </Layout>
)

export default Business;